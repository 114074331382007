import React, { useEffect, useRef, useState } from "react";
import MainPage from "./pages/MainPage";
import SkillPage from "./pages/SkillPage";
import AboutMePage from "./pages/AboutMePage"; // 새로운 페이지 추가
import Navbar from "./components/Navbar/Navbar";
import ProjectPage from "./pages/ProjectPage";
import WorkPage from "./pages/WorkPage";
import IngPage from "./pages/IngPage";

function App() {
  const [isMainVisible, setIsMainVisible] = useState(false); //메인
  const [isSkillVisible, setIsSkillVisible] = useState(false); //스킬
  const [isAboutMeVisible, setIsAboutMeVisible] = useState(false); // 어바웃미
  const [isProjectVisible, setIsProjectVisible] = useState(false); // 프로젝트
  const [isWorksVisible, setIsWorksVisible] = useState(false); // 경력및교육
  const [isIngVisible, setIsIngVisible] = useState(false); // 계속 업데이트중

  const mainRef = useRef(null); // 메인 Ref
  const skillRef = useRef(null); //스킬 Ref
  const aboutMeRef = useRef(null); // 어바웃미 Ref
  const projectRef = useRef(null); // 프로젝트 Ref
  const worksRef = useRef(null); // 프로젝트 Ref
  const ingRef = useRef(null); // 계속 업데이트중 Ref

  useEffect(() => {
    // ref 값을 변수에 할당
    const mainRefCurrent = mainRef.current;
    const skillRefCurrent = skillRef.current;
    const aboutMeRefCurrent = aboutMeRef.current;
    const projectRefCurrent = projectRef.current;
    const worksRefCurrent = worksRef.current;
    const ingRefCurrent = ingRef.current;

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.id === "home") {
            setIsMainVisible(true);
          } else if (entry.target.id === "skill") {
            setIsSkillVisible(true);
          } else if (entry.target.id === "aboutMe") {
            setIsAboutMeVisible(true);
          } else if (entry.target.id === "project") {
            setIsProjectVisible(true);
          } else if (entry.target.id === "works") {
            setIsWorksVisible(true);
          } else if (entry.target.id === "ing") {
            setIsIngVisible(true);
          }
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // 각 ref를 관찰
    if (mainRefCurrent) observer.observe(mainRefCurrent);
    if (skillRefCurrent) observer.observe(skillRefCurrent);
    if (aboutMeRefCurrent) observer.observe(aboutMeRefCurrent);
    if (projectRefCurrent) observer.observe(projectRefCurrent);
    if (worksRefCurrent) observer.observe(worksRefCurrent);
    if (ingRefCurrent) observer.observe(ingRefCurrent);

    // 클린업 함수에서 각 ref를 관찰 해제
    return () => {
      if (mainRefCurrent) observer.unobserve(mainRefCurrent);
      if (skillRefCurrent) observer.unobserve(skillRefCurrent);
      if (aboutMeRefCurrent) observer.unobserve(aboutMeRefCurrent);
      if (projectRefCurrent) observer.unobserve(projectRefCurrent);
      if (worksRefCurrent) observer.unobserve(worksRefCurrent);
      if (ingRefCurrent) observer.unobserve(ingRefCurrent);
    };
  }, []);

  return (
    <>
      <Navbar />
      <section id="home" ref={mainRef} style={{ minHeight: "100vh" }}>
        {isMainVisible && <MainPage />}
      </section>
      <section id="aboutMe" ref={aboutMeRef} style={{ minHeight: "100vh" }}>
        {/* 새로운 섹션 추가 */}
        {isAboutMeVisible && <AboutMePage />}
      </section>
      <section id="project" ref={projectRef} style={{ minHeight: "100vh" }}>
        {isProjectVisible && <ProjectPage />}
      </section>
      <section id="skill" ref={skillRef} style={{ minHeight: "100vh" }}>
        {isSkillVisible && <SkillPage />}
      </section>
      <section id="works" ref={worksRef} style={{ minHeight: "100vh" }}>
        {isWorksVisible && <WorkPage />}
      </section>
      <section id="ing" ref={ingRef} style={{ minHeight: "100vh" }}>
        {isIngVisible && <IngPage />}
      </section>
    </>
  );
}

export default App;
