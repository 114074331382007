import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

// 둥실둥실거리는 애니메이션 정의
const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

// 좌우로 움직이는 애니메이션 정의
const arrowLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
`;

const arrowRight = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
`;

// 페이드 인 애니메이션 정의
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AboutCon = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const BoxDiv = styled.div`
  width: 70%;
  height: 400px;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;

const FirstChildDiv = styled.div`
  width: 15%;
  display: flex;
  align-items: end;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out 1s forwards; /* 1.5초 후 페이드 인 */

  .first_box {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: end;
    div:first-child {
      background-color: white;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      animation: ${float} 3s ease-in-out infinite;
    }
    div:last-child {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: white;
      position: relative;
      bottom: 80px;
      left: 35px;
      animation: ${float} 3s ease-in-out infinite;
      animation-delay: 0.5s; /* 두 요소의 애니메이션 시작 시간을 다르게 설정 */
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    .first_box {
      div:first-child,
      div:last-child {
        width: 50px;
        height: 50px;
      }
      div:last-child {
        bottom: 40px;
        left: 20px;
      }
    }
  }
`;

const SecondChildDiv = styled.div`
  width: 70%;
  align-content: end;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out 0.5s forwards; /* 0.5초 후 페이드 인 */

  .text_box {
    position: relative;
    left: 100px;

    p {
      font-size: 20px;
      font-weight: 100;
      padding: 10px;
    }
    div:nth-child(1) {
      h1 {
        position: relative;
        color: black;
        left: 50px;
        font-size: 50px;
        -webkit-text-stroke: 1px white;
      }
    }
    div:nth-child(2) {
      display: flex;
      span:hover {
        cursor: pointer;
        color: #ffffff2f;
        transition: color 0.6s ease-in;
      }
      span {
        transition: color 0.6s ease-in;
      }
      h1,
      h2 {
        font-size: 60px;
        padding: 10px;
      }
      h1:first-child {
        animation: ${arrowLeft} 1s ease-in-out infinite;
      }

      h1:last-child {
        animation: ${arrowRight} 1s ease-in-out infinite;
        z-index: 0;
      }
    }
    div:nth-child(3) {
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    .text_box {
      left: 0;
      p {
        font-size: 16px;
      }
      div:nth-child(1) h1 {
        left: 0;
        font-size: 30px;
        text-align: center;
      }
      div:nth-child(2) {
        align-items: center;
        h2 {
          font-size: 20px;
        }
      }
      div:nth-child(3) {
        p {
          text-align: center;
        }
      }
    }
  }
`;

const ThirdChildDiv = styled.div`
  width: 15%;
  align-content: start;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out 2s forwards; /* 2.5초 후 페이드 인 */

  .last_box {
    width: 100%;
    height: 35%;
    display: flex;
    align-items: end;
    transform: scaleX(-1); /* 좌우 반전 */

    div {
      transform: scaleX(-1); /* 텍스트와 콘텐츠를 올바른 방향으로 유지 */
    }

    div:first-child {
      background-color: white;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      animation: ${float} 3s ease-in-out infinite;
    }

    div:last-child {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: white;
      position: relative;
      bottom: 100px;
      left: 35px;
      animation: ${float} 3s ease-in-out infinite;
      animation-delay: 0.5s; /* 두 요소의 애니메이션 시작 시간을 다르게 설정 */
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    top: 100px;
    .last_box {
      justify-content: start;
      div:first-child,
      div:last-child {
        width: 50px;
        height: 50px;
      }
      div:last-child {
        bottom: 50px;
        left: 20px;
      }
    }
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll; /* Enable scrolling */
  animation: ${fadeIn} 1s ease-in-out; /* Add fadeIn animation */

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const CloseButton = styled.button`
  align-self: flex-end;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Box = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgb(0 0 0 / 0%);
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  color: white;

  h1 {
    font-size: 2.5vh;
    background-color: rgb(0 0 0 / 0%);
    margin: 0;
  }

  p {
    font-size: 2vh;
    margin: 10px 0 0;
  }
  @media (max-width: 767px) {
    h1 {
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

// const SkillList = styled.ul`
//   list-style: none;
//   padding: 0;

//   li {
//     color: #c3c3c3;
//     font-weight: bold;
//     font-size: 2vh;
//     margin: 5px 0;
//   }

//   u {
//     color: antiquewhite;
//   }
// `;

export default function AboutMeComponent() {
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <AboutCon>
      <BoxDiv>
        <FirstChildDiv>
          <div className="first_box">
            <div></div>
            <div></div>
          </div>
        </FirstChildDiv>
        <SecondChildDiv>
          <div className="text_box">
            <div>
              <h1> CONECT DEVELOPER</h1>
            </div>
            <div>
              <h1>⇨</h1>
              <span onClick={handleShowPopup}>
                <h2>BONG HYUN KIM</h2>
              </span>
              <h1>⇦</h1>
            </div>
            <div>
              <p>
                ABVLD LWIJEF SLKJDF SDKJFOSIEFJDJ
                <br />
                <br />
                SLKDJF EIEJFOS DKJFJSDLIKJFOIJWEOIKJGEORIJ
                <br />
                <br />
                GREDKLJHG;BLWRJ;LI YAGEDRROL; GREABKJWELI WED
              </p>
            </div>
          </div>
        </SecondChildDiv>
        <ThirdChildDiv>
          <div className="last_box">
            <div></div>
            <div></div>
          </div>
        </ThirdChildDiv>
      </BoxDiv>
      {showPopup && (
        <PopupContainer>
          <CloseButton onClick={handleClosePopup}>X</CloseButton>
          <Box>
            <h1>
              안녕하세요. 끊임없이 성장하며 혁신적인 솔루션을 제공하는 개발자가
              되고 싶은 김봉현 입니다!
            </h1>
          </Box>
          <Box>
            <h1>
              제가 개발자로 전향하게 된 이유는 국비교육 6개월 과정 동안 개발
              기술을 배우며 코딩의 즐거움을 느낀 나 자신을 발견했기 때문입니다.
              개발하는 과정 자체가 매우 신기하고 재미있었습니다. 코드만으로
              사용자가 원하는 서비스를 만들어 내는 과정에서 큰 만족감을
              느꼈으며, 그로 인해 사용자에게 좋은 영향을 줄 수 있다는 점이
              저에게 큰 가치를 주었습니다.
            </h1>
          </Box>
          <Box>
            <h1>
              수료 후, 아는 지인의 회사에서 프리랜서로 활동하면서 개발자로의
              전환에 대한 확신이 더욱 명확해졌습니다. 키오스크 관련 회사에서
              3개월간 서포트 업무를 맡아, CMS 및 키오스크 프론트엔드 바인딩,
              기능 구현 업무를 수행했습니다.
            </h1>
          </Box>
          <Box>
            <h1>
              이 과정에서 실제 프로젝트의 개발 과정을 체험하고, 팀과 협력하여
              문제를 해결하는 과정을 통해 기술이 실제로 어떻게 적용되는지를 배울
              수 있었습니다. 이러한 경험을 통해 개발에 대한 더 깊은 이해와
              열정을 갖게 되었습니다.
            </h1>
          </Box>
          <Box>
            <h1>
              최근에 개발한 프로젝트 중 가장 기억에 남는 하나는 [새싹
              허브]입니다. 이 프로젝트는 새싹 도봉점에 있는 크루와 관계자들이
              사용하는 공간 예약(스터디룸) 커뮤니티입니다. 도봉점 관계자분들이
              실제 서비스를 고려하여 회의를 통해 서비스 방식을 논의하고,
              프로젝트에 필요한 사항과 비용 등을 전반적으로 검토하는 과정에서 큰
              기대를 품게 되었습니다. 이를 통해 제가 개발한 기술이 실제
              사용자들에게 어떤 가치를 줄 수 있는지 직접 경험하고 느낄 수
              있었습니다.
            </h1>
          </Box>
          <Box>
            <h1>
              저는 꾸준함과 성실함을 중요하게 생각하며, 장기적으로 함께 성장할
              수 있는 환경을 선호합니다. 새로운 기술을 배우고, 이를 실무에
              적용하는 데 큰 열정을 가지고 있으며, 지속적인 자기 개발을 통해
              회사와 함께 발전해 나가고자 합니다. 귀사의 비전과 목표에 기여하며,
              장기적으로 함께 성장할 수 있는 개발자가 되겠습니다.
            </h1>
          </Box>
        </PopupContainer>
      )}
    </AboutCon>
  );
}
