import styled from 'styled-components';
import IngConponent from '../components/Main/IngConponent';

const IngSection = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

export default function IngPage(){
    return(
        <IngSection>
            <IngConponent/>
        </IngSection>
    )
}