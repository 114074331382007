import styled from "styled-components";
import ProjectComponnet from "../components/Main/ProjectComponent";

const ProjectSection = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  color: white;
  flex-direction: column;
  align-items: center;
  background-color: black;

`;

export default function ProjectPage() {
  return (
    <ProjectSection>
      <ProjectComponnet />
    </ProjectSection>
  );
}
