import styled,{keyframes} from "styled-components";
const line=keyframes`
    from{
        width: 0;
    }
    to{
        width:700px
        }
`
const mobileline=keyframes`
    from{
        width: 0;
    }
    to{
        width:300px
        }
`
const bottomIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const WorkSection = styled.div`
  width: 90%;
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  & > h1 {
    font-size: 70px;
    margin: 10px 0;
  }
  h5 {
    font-size: 30px;
    margin: 10px 0;
  }
  img{
    width: 30px;
    height: 30px;
  }
  p {
    font-size: 20px;
    font-weight: 100;
    margin: 10px 0;
  }
  hr{
   animation: ${line} 3s;
   width: 700px;
  }
  @media (max-width: 767px) {
    h1{
      font-size: 45px;
    }
    hr{
      animation: ${mobileline} 3s;
      width: 300px;
    }
    h5{
      font-size: 20px;
    }
    img{
      width: 20px;
      height: 20px;
    }
    p{
      font-size: 14px;
    }
  }
`;

const WorkArea = styled.div`
  width: 100%;
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  animation: ${bottomIn} 2s;
  animation-delay: 0.5s;
  
  & > div {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div {
      display: flex;
      justify-content: space-between;
     
    }
    &:hover{
        cursor: pointer;
        background-color: #ffffff2b;
        transition:background-color 0.3s ease-in ;
    }
    
  }
`;
export default function WorkComponent() {
  return (
    <WorkSection>
      <h1>Work Experience</h1>
      <hr />
      <WorkArea>
        <div>
          <div>
            <h5>Acorncampus</h5>
            <img src="/img/arrow.png" alt="" />
          </div>
          <p>스마트 웹&앱을 활용한 Iot 통합 솔루션 개발자 과정</p>
          <p>Nov 2022 - May 2023</p>
        </div>
        <div>
          <div>
            <h5>Sesac(Coding ON)</h5>
            <img src="/img/arrow.png" alt="" />
          </div>
          <p>
            기획부터 배포까지 협업 프로젝트로 완성하는 풀스텍 개발자 취업
            프로젝트
          </p>
          <p>Dec 2022 - July 2024</p>
        </div>
      </WorkArea>
    </WorkSection>
  );
}
